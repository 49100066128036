#welcome {
    font-family: HelveticaNeueCyr;
    font-size: 16px;
    width: 100%;
    h1 {
        font-size: 3em;
        // margin-top: 40px;
        text-align: center;
    }
    .offer {
        font-size: 1.5em;
        margin-top: 35px;
        text-align: center;
        line-height: 43px;
        span {
            font-weight: bold;
        }
    }
    .to_phone {
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        background-color: #fff;
        width: 560px;
        height: 80px;
        font-weight: bold;
        color: #b30101;
        text-align: center;
        font-size: 1.7em;
        text-transform: uppercase;
        margin: 0 auto;
        border-radius: 14px;
        box-shadow: 0px 0px 13.3px 0.7px rgba(0, 0, 0, 0.2);
        margin-top: 60px;
        &:active {
            border: 3px solid rgb(179, 1, 1);
            box-shadow: 0px 0px 13.3px 0.7px rgba(179, 1, 1, 0.2);
        }
    }
    .to_services {
        display: flex;
        justify-content: center;
        color: #000;
        font-size: 1.5em;
        text-decoration: none;
        position: relative;
        // margin-top: 60px;
        &::after {
            content: '';
            bottom: -2px;
            display: flex;
            background-color: #000;
            height: 2px;
            width: 320px;
            position: absolute;
        }
        &:active {
            color:  rgb(179, 1, 1);
            box-shadow: 0px 0px 13.3px 0.7px rgba(179, 1, 1, 0.2);
        }
    }
    .to_phone_history {
        display: block;
        text-decoration: none;
        background-color: #fff;
        width: 250px;
        height: 80px;
        font-weight: bold;
        line-height: 40px;
        color: #000;
        text-align: center;
        font-size: 1.3em;
        text-transform: uppercase;
        border-radius: 14px;
        box-shadow: 0px 0px 13.3px 0.7px rgba(0, 0, 0, 0.2);
        // margin: 60px 0 0 auto;
        &:active {
            border: 3px solid rgb(179, 1, 1);
            box-shadow: 0px 0px 13.3px 0.7px rgba(179, 1, 1, 0.2);
        }
    }
    .to_privacy {
        display: block;
        width: max-content;
        height: 50px;
        line-height: 50px;
        color: #000;
        font-size: 1em;
        margin: 19px auto 0;
        font-weight: 300;
    }
}
