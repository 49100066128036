$scissorsdis: '../../assets/img/scissorsdis.png';
$scissorseneb: '../../assets/img/scissorseneb.png';
#after_order {
    font-family: HelveticaNeueCyr;
    font-size: 16px;
    font-weight: bold;
    h1 {
        text-align: center;
        font-size: 36px;
        // margin: 0 auto 33px;
    }
    .cutbrick_wrapper {
        display: flex;
        justify-content: center;
        input {
            display: none;
        }
        .cutbrick {
            border-radius: 14px;
            background-color: #e8e8e8;
            width: 130px;
            height: 130px;
            background-image: url($scissorsdis);
            background-repeat: no-repeat;
            background-position: center center;
            margin-right: 41px;
        }
        input:checked~.cutbrick {
            border: 3px solid rgb(179, 1, 1);
            background-color: rgb(255, 255, 255);
            box-shadow: 0px 0px 13.3px 0.7px rgba(179, 1, 1, 0.2);
            background-image: url($scissorseneb);
        }
    }
    .ournumber {
        text-align: center;
        font-size: 50px;
        font-weight: bold;
        // margin-top: 35px;
        
        span {
            font-size: 65px;
            color: red;
            margin-left: 20px;
        }
    }
    .japanwomen {
        display: block;
        margin: 30px auto;

    }
    .greetful {
        font-size: 40px;
        text-align: center;
        margin-top: 30px;
    }
    .sps {
        font-size: 40px;
        text-align: center;
        margin-top: 20px;
        color: #b30101;
        text-transform: uppercase;
    }
    
}