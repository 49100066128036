#phone {
    font-family: HelveticaNeueCyr;
    font-size: 16px;
    width: 100%;
    h1 {
        display: block;
        // margin-top: 10px;
        font-size: 2.5em;
        text-align: center;
    }
    .inputfield {
        border-radius: 14px;
        box-shadow: inset 0px 0px 13.3px 0.7px rgba(0, 0, 0, 0.2);
        width: 460px;
        height: 84px;
        // margin: 25px auto 25px;
        line-height: 84px;
        font-size: 42px;
        font-weight: 500;
        text-align: left;
        padding-left: 40px;
        span {
            color: #bfbfbf;
        }
    }
    .btn_container {
        display: flex;
        justify-content: center;
        /* height: 134px; */
        position: relative;
        align-items: center;
        .to_back {
            text-decoration: none;
            color: #000;
            font-size: 28px;
            font-weight: bold;
            position: relative;
            position: absolute;
            left: 0;
            // margin-bottom: -50px;
            &::after {
                content: "";
                display: block;
                position: absolute;
                top: 32px;
                background-color: #000;
                width: 129px;
                height: 3px;
            }
        }
        .phone_next {
            font-weight: bold;
            line-height: 80px;
            text-align: center;
            font-size: 28px;
            border-radius: 14px;
            box-shadow: 0px 0px 13.3px 0.7px rgba(0, 0, 0, 0.2);
            width: 340px;
            background-color: white;
            // margin: 15px 0 0;
            text-decoration: none;
            border: none;
            // margin-bottom: -50px;
            &:active {
                border: 3px solid rgb(179, 1, 1);
                box-shadow: 0px 0px 13.3px 0.7px rgba(179, 1, 1, 0.2);
            }
        }
    }
}