.login {
    font-family: HelveticaNeueCyr;
    font-weight: 900;
    font-style: normal;
    display: grid;
    grid-template-rows: 0.2fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    grid-template-areas: "header header" "form keyboard" "loginbtn loginbtn";
    @media (max-width: '768px') {
        grid-template-areas: "header" "form" "keyboard" "loginbtn";
        grid-template-columns: 1fr;
    }
    h1 {
        font-size: 36px;
        text-align: center;
        grid-area: header;
        margin-top: 20px;
    }
    form {
        grid-area: form;
        justify-self: right;
        @media (max-width: '768px') {
            justify-self: center;
        }
        input {
            border: none;
            border-radius: 14px;
            box-shadow: inset 0px 0px 13.3px 0.7px rgba(0, 0, 0, 0.2);
            text-align: center;
            height: 84px;
            line-height: 84px;
            font-size: 26px;
            font-family: HelveticaNeueCyr;
            font-weight: 500;
            display: block;
            margin: 0 auto;
            min-width: 330px;
            &:nth-child(2) {
                margin-top: 35px;
            }
        }
    }
    .keyboardWrap {
        grid-area: keyboard;
        justify-self: left;
        @media (max-width: '768px') {
            justify-self: center;
        }
    }
    .loginbtn {
        border: none;
        width: 100%;
        height: 84px;
        font-size: 32px;
        border-radius: 14px;
        background-color: white;
        box-shadow: 0px 0px 13.3px 0.7px rgba(0, 0, 0, 0.2), 0 0 15px 2px rgba(0, 0, 0, 0.5);
        font-family: HelveticaNeueCyr;
        font-weight: bold;
        grid-area: loginbtn;
        max-width: 330px;
        margin: 0 auto;
        margin-bottom: 20px;
    }
}