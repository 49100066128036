#services {
    font-family: HelveticaNeueCyr;
    font-size: 16px;
    width: 100%;
   
    display: flex;
    flex-wrap: wrap;
    &::after {
        content: "";
        height: 0px;
        display: block;
        clear: both;
    }
    h2 {
        font-size: 36px;
        // margin: 0 auto 20px;
    }
    input {
        display: none;
    }
    label {
       
    max-width: 100%;
    }
    .serv__data {
        float: left;
        border-radius: 14px;
        background-color: white;
        box-shadow: 0px 0px 23.3px 0.7px rgba(0, 0, 0, 0.2);
        /* box-shadow: inset 0px 0px 20.3px 4.7px rgba(0, 0, 0, 0.2); */
       width: 100%;
        // height: 100px;
        text-align: center;
        // font-size: 24px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        font-weight: bold;
        .serv_name {
            width: 71%;
        }
        .serv_price {
            width: 23%;
            font-size: 39px;
            letter-spacing: -3px;
            &::first-letter {
                color: #b30101;
            }
        }
    }
    .services_main_block {
        display: grid;
        width: 100%;
        grid-template-columns: 2fr 1fr;
        grid-gap: 25px;
        // margin-bottom: 30px;
        .serv__data {
            background-color: rgba(253, 194, 68, 0.15);
            margin-right: 0;
        }
        .services_main_block__right {
            .serv:first-child  .serv__data{
                margin-right: 73px;
                width: 100%;
                // height: 350px;
                position: relative;
                background-color: rgba(253, 194, 68, 0.15);
                .serv_name {
                    font-size: 88px;
                }
                .serv_price {
                    font-size: 107px;
                }
            }
        }
       
        .services_main_block__left {
            display: grid;
            grid-template-rows: 1fr 1fr 1fr;
            grid-gap: 25px;
           
        }
        // .serv:first-child 
    }
    .services_extra_block {
        display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    // margin-bottom: 10px;
    grid-gap: 25px;
    }
    .services_styling_block {
        width: 100%;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    grid-gap: 25px;
        input:checked~.serv__data {
            border: 3px solid rgb(179, 1, 1);
            color: #b30101;
        }
        .serv__data {
            flex-direction: column;
           
            .serv_price {
                font-size: 29px;
            }
        }
        label:last-child .serv__data {
            margin-right: 0;
        }
        .hair_styling {
            float: left;
            border-radius: 14px;
            box-shadow: 0px 0px 23.3px 0.7px rgba(0, 0, 0, 0.2);
            width: 275px;
            height: 110px;
            text-align: center;
            font-size: 27.5px;
            margin-right: 73px;
            margin-bottom: 27px;
            position: relative;
            background-color: rgb(255, 176, 176);
            display: flex;
            align-items: center;
            span {
                z-index: 10;
                font-weight: bold;
            }
            &::after {
                display: block;
                content: '';
                position: absolute;
                top: 13px;
                left: 220px;
                border-radius: 14px;
                background-color: rgb(255, 255, 255);
                width: 85px;
                height: 85px;
                transform: rotate(45deg);
                background-color: rgb(255, 176, 176);
            }
        }
    }
    .btn_container {
        display: flex;
        justify-content: center;
        position: relative;
        align-items: center;
        width: 100%;
        .to_back {
            text-decoration: none;
            color: #000;
            font-size: 28px;
            font-weight: bold;
            position: relative;
            position: absolute;
            left: 0;
            margin-bottom: -50px;
            &::after {
                content: "";
                display: block;
                position: absolute;
                top: 32px;
                background-color: #000;
                width: 129px;
                height: 3px;
            }
        }
        .services_next {
            font-weight: bold;
            line-height: 80px;
            text-align: center;
            font-size: 28px;
            border-radius: 14px;
            box-shadow: 0px 0px 13.3px 0.7px rgba(0, 0, 0, 0.2);
            width: 340px;
            background-color: white;
            margin: 15px;
            text-decoration: none;
            border: none;
            margin-bottom: -50px;
        }
    }
    input:checked~.serv__data {
        border: 3px solid rgb(179, 1, 1);
        color: #b30101;
    }
}