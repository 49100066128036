$backspace: '../../assets/img/backspace.png';
.keyboard {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 390px;
    width: 310px;
    margin: 0 auto;
    font-family: HelveticaNeueCyr;
    font-weight: 500;
    .btn {
        border-radius: 14px;
        background-color: rgb(232, 232, 232);
        width: 80px;
        height: 80px;
        line-height: 80px;
        text-align: center;
        font-size: 48px;
        &:active {
            border: 3px solid rgb(179, 1, 1);
            border-radius: 14px;
            box-shadow: 0px 0px 13.3px 0.7px rgba(179, 1, 1, 0.2);
        }
        &:last-child {
            background: center center no-repeat rgb(232, 232, 232) url($backspace);
        }
    }
}