$men_dis: '../../assets/img/mendis.png';
$women_dis: '../../assets/img/womendis.png';
$men_eneb: '../../assets/img/meneneb.png';
$women_eneb: '../../assets/img/womeneneb.png';
#gender_age {
    display: grid;
    grid-template: "gender age" 1fr "bnt_block bnt_block" / 3fr 2fr;
    font-family: HelveticaNeueCyr;
    font-size: 16px;
    grid-auto-rows: 60px;
    grid-auto-columns: 5fr;
    .gender {
        grid-area: gender;
        display: flex;
        flex-flow: wrap;
        justify-content: center;
        // margin-top: 15px;
        h2 {
            font-size: 36px;
            text-align: center;
            span {
                font-size: 21px;
            }
        }
        .gender_boxes {
            input {
                display: none;
            }
            .box {
                display: inline-block;
                border-radius: 14px;
                box-shadow: inset 0px 0px 13.3px 0.7px rgba(0, 0, 0, 0.2);
                // width: 220px;
                // height: 450px;
                background: center 67px no-repeat rgb(255, 255, 255);
                box-sizing: border-box;
                h3 {
                    margin-top: 25px;
                    font-size: 28px;
                    text-align: center;
                }
            }
            .male {
                margin-right: 25px;
                background-image: url($men_dis);
            }
            .female {
                background-image: url($women_dis);
            }
            #male:checked~.male {
                border: 3px solid rgb(179, 1, 1);
                background-image: url($men_eneb);
            }
            
            #female:checked~.female {
                border: 3px solid rgb(179, 1, 1);
                background-image: url($women_eneb);
            }
        }
    }
    .age {
        grid-area: age;
        // margin-top: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        h2 {
            font-size: 36px;
            margin-bottom: 65px;
        }
        input {
            display: none;
        }
        .agebricks {
            border-radius: 14px;
            box-shadow: 0px 0px 13.3px 0.7px rgba(0, 0, 0, 0.2);
            // width: 300px;
            // height: 45px;
            // font-size: 22px;
            text-align: center;
            // margin-bottom: 19px;
            display: flex;
        justify-content: center;
        align-items: center;
            background-color: rgb(255, 255, 255);
            font-weight: bold;
        }
        input:checked~.agebricks {
            border: 3px solid rgb(179, 1, 1);
        }
    }
    .bnt_block {
        grid-area: bnt_block;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        .to_back {
            text-decoration: none;
            color: #000;
            font-size: 28px;
            font-weight: bold;
            position: relative;
            position: absolute;
            left: 0;
            // margin-bottom: -50px;
            &::after {
                content: "";
                display: block;
                position: absolute;
                top: 32px;
                background-color: #000;
                width: 129px;
                height: 3px;
            }
        }
        .gender_next {
            border: none;
            font-weight: bold;
            line-height: 80px;
            color: #b30101;
            text-align: center;
            font-size: 28px;
            border-radius: 14px;
            box-shadow: 0px 0px 13.3px 0.7px rgba(0, 0, 0, 0.2);
            width: 340px;
            background-color: white;
            margin: 32px;
            text-decoration: none;
            // margin-bottom: -50px;
            &:active {
                border: 3px solid rgb(179, 1, 1);
                box-shadow: 0px 0px 13.3px 0.7px rgba(179, 1, 1, 0.2);
            }
        }
    }
    
}