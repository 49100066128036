#history {
    font-family: HelveticaNeueCyr;
    font-size: 16px;
    h1 {
        // font-size: 2em;
        text-align: center;
        // margin-top: 1.5em;
        // margin: 10px 0 10px;
    }
    table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 1rem;
        color: #212529;
        border: 1px solid #dee2e6;
        thead th {
            vertical-align: bottom;
            border-bottom: 2px solid #dee2e6;
        }
        tbody tr:nth-of-type(odd) {
            background-color: rgba(0, 0, 0, .05);
        }
        td,
        th {
            border: 1px solid #dee2e6;
            padding: .75rem;
            vertical-align: top;
            text-align: center;
        }
    }
    nav {
        display: flex;
        justify-content: space-between;
        margin-top: 3em;
        .to_serv_form_history {
            text-decoration: none;
            background-color: #fff;
            font-weight: bold;
            color: #b30101;
            text-align: center;
            font-size: 1.5em;
            padding: 1em;
            text-transform: uppercase;
            border-radius: 14px;
            box-shadow: 0px 0px 13.3px 0.7px rgba(0, 0, 0, 0.2);
        }
        .to_next,
        .to_back {
            font-weight: 600;
            font-size: 20px;
            border: none;
            background: transparent;
        }
    }
    .to_welcome_form_history {
        display: block;
        color: #000;
        text-align: center;
        font-size: 24px;
        font-weight: 600;
        // margin: 55px auto -50px;
    }
    
}