#congrat_window_wrapper {
    position: fixed;
    font-family: HelveticaNeueCyr;
    font-size: 16px;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(128, 128, 128, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    .congrat_window {
        background: #fff;
        justify-content: center;
        // display: grid;
        align-items: center;
        border-radius: .5em;
        font-size: 2em;
        text-align: center;
        position: relative;
        width: 520px;
        height: 535px;
        z-index: 10;
        padding: 45px;
        .balloon {
            position: absolute;
            top: -50px;
            left: -36px;
        }
        .border {
            position: absolute;
            top: 312px;
            left: 269px;
        }
        h3 {
            text-align: right;
            font-size: 40px;
            color: #b30101;
            margin-top: 145px;
        }
        h2 {
            text-align: center;
            font-size: 40px;
            color: #b30101;
            margin-top: 97px;
        }
        .onep {
            font-size: 26px;
            text-align: right;
            font-weight: bold;
            margin-top: 10px;
            height: 60px;
            padding-left: 50%;
        }
        .twop {
            font-size: 32px;
            text-align: right;
            margin-top: 21px;
            margin-right: 19px;
            font-weight: bold;
        }
        .threered {
            color: #b30101;
            font-weight: bold;
        }
        
    }
}