/* @import '/src/assets/fonts/fonts.css'; */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  letter-spacing: 0.75px;
  user-select: none;
}
/* 
@font-face {
  font-family: myBlack;
  src: url(/src/assets/fonts/HelveticaNeueCyr-Black.woff) format('woff');
}

@font-face {
  font-family: myBold;
  src: url(/src/assets/fonts/HelveticaNeueCyr-Bold.woff) format('woff');
}

@font-face {
  font-family: myLight;
  src: url(/src/assets/fonts/HelveticaNeueCyr-Light.woff);
}

@font-face {
  font-family: myMedium;
  src: url(/src/assets/fonts/HelveticaNeueCyr-Medium.woff) format('woff');
}

@font-face {
  font-family: myRoman;
  src: url(/src/assets/fonts/HelveticaNeueCyr-Roman.woff);
} */
