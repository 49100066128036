$background: "./assets/img/Background.png";
$logo: "./assets/img/logo.png";

.container {
  padding: 60px;
  background: 10px 10px / auto 60px no-repeat url($logo), top center / cover no-repeat fixed url($background);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: "768px") {
    padding: 40px;
  }
  @media (max-width: "576px") {
    padding: 40px 20px;
  }
}
