#timer_window_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(128, 128, 128, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    .timer_window {
        min-width: 580px;
        width: 50%;
        min-height: 450px;
        height: 50%;
        background: #fff;
        justify-content: center;
        display: grid;
        align-items: center;
        border-radius: .5em;
        font-size: 2em;
        text-align: center;
        grid-template: "header header" 0.2fr "timer btn_container" 1fr / 1fr 1fr;
        h3 {
            grid-area: header;
        }
        .timer {
            grid-area: timer;
            font-size: 220px;
            font-weight: 400;
        }
        .btn_container {
            grid-area: btn_container;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            height: 100%;
            .yes,
            .not,
            .backtomain {
                background-color: #fff;
                width: 250px;
                height: 80px;
                font-weight: bold;
                line-height: 80px;
                color: #b30101;
                font-size: 26px;
                text-transform: uppercase;
                border-radius: 14px;
                box-shadow: 0px 0px 13.3px 0.7px rgba(0, 0, 0, 0.2);
                text-decoration: none;
                &:active {
                    border: 3px solid rgb(179, 1, 1);
                    box-shadow: 0px 0px 13.3px 0.7px rgba(179, 1, 1, 0.2);
                }
            }
            .backtomain {
                line-height: 41px;
            }
        }
    }
}