#privacy {
    font-family: HelveticaNeueCyr;
    font-size: 16px;
    .text {
        margin: 100px 20px 0 20px;
        height: 57vh;
        overflow-y: scroll;
        line-height: 30px;
        h2 {
            font-size: 35px;
            padding: 50px 0 20px 0;
            line-height: 50px;
        }
        p {
            padding: 0 33px;
            font-size: 21px;
            line-height: 40px;
            text-align: justify;
        }
        li {
            list-style-position: inside;
            padding: 0 60px;
            font-size: 21px;
            line-height: 40px;
            text-align: justify;
        }
    }
    .toindexfromprivacy {
        display: block;
        text-decoration: none;
        background-color: #fff;
        width: 330px;
        height: 80px;
        line-height: 80px;
        color: #000;
        text-align: center;
        font-size: 21px;
        text-transform: uppercase;
        z-index: 1;
        border-radius: 14px;
        box-shadow: 0px 0px 13.3px 0.7px rgba(0, 0, 0, 0.2);
        margin: 53px auto 0;
        &:active {
            border: 3px solid rgb(179, 1, 1);
            box-shadow: 0px 0px 13.3px 0.7px rgba(179, 1, 1, 0.2);
        }
    }
}